import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OfferZone from "@/_component/Common/theme/offer-zone/OfferZone";
import { getCurrentSession } from "@/endpoints/amplify/auth";
import { API_PUBLIC_HOST, S3_HOST } from "@/_constant/urlconstants";
import { useLanguageContext } from "@/hooks/context-hooks";
import MainAdsLayout from "@/_component/MainAdsLayout";
import PageTitle from "@/_component/Common/page/PageTitle";
import ImageCard from "@/_component/Common/card/ImageCard";

const Dealership = () => {
    const { language } = useLanguageContext();
    const { state } = useLocation();
    let navigate = useNavigate();
    const { data, route, categoryId, editType } = state ? JSON.parse(state) : {};

    const [currentRouteData, setCurrentRouteData] = useState(data);
    const [catgoryList, setCategory] = useState([]);

    const [activeDealerShip, setActiveDealerShip] = useState([]);
    const [inactiveDealerShip, setInactiveDealerShip] = useState([]);

    useEffect(() => {
        getCurrentSession(getlistUserAccess);
    }, []);


    const getlistUserAccess = (success, user, jwtToken) => {
        const URL = `${API_PUBLIC_HOST}/user/listUserAccess?filterActiveDates=false`;

        axios({
            method: "get",
            url: URL,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: jwtToken,
            },
        }).then((response) => {
            var categories = response?.data?.response;

            let [activeCategory, inActiveCategory] = [[], []];

            for (let index = 0; index < categories.length; index++) {
                if (categories[index]?.category_id != "-1" && categories[index]?.category_id != "-2" && categories[index]?.category_id != "-3") {
                    if (categories[index]?.plan_days_remaining < 0 || categories[index]?.status != "APPROVED") {
                        inActiveCategory.push(categories[index]);
                    } else {
                        activeCategory.push(categories[index]);
                    }

                }
            }
            setActiveDealerShip(activeCategory);
            setInactiveDealerShip(inActiveCategory);
        });
    };

    return (
        <div>
            <MainAdsLayout sideBanner>
                <PageTitle headingPart1={"My"} headingPart2={"Dealerships"}></PageTitle>
                <div className="px-lg-3">
                    <div className="row">
                        <div className="fw-bold fs-5">Active Dealerships</div>
                        <div className="row row-cols-lg-4 row-cols-sm-4 row-cols-2 gy-3 text-center">
                            {activeDealerShip?.map((activeDealer, i) => (
                                <div key={i} className="col">
                                    <a href={`/dealership-detail/` + activeDealer.user_plan_id}>
                                        <ImageCard src={S3_HOST + activeDealer?.category_thumbnail} label={activeDealer?.category_name}/>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="fw-bold fs-5">Inactive Dealerships</div>
                        <div className="row row-cols-lg-4 row-cols-sm-4 row-cols-2 gy-3 text-center">
                            {inactiveDealerShip?.map((inActive, i) => (
                                <div key={i} className="col">
                                    <a href={`/dealership-detail/` + inActive.user_plan_id}>
                                        <ImageCard src={S3_HOST + inActive?.category_thumbnail} label={inActive?.category_name}/>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </MainAdsLayout>
            <OfferZone />
        </div>
    );
};

export default Dealership;