import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'
import OfferZone from "@/_component/Common/theme/offer-zone/OfferZone";
import request from "@/apis/request";
import MainAdsLayout from "@/_component/MainAdsLayout";
import PageTitle from "@/_component/Common/page/PageTitle";
import { MY_LEADS, DEALERPOSTINGS, PROFILE_DETAILS } from "@/_constant/urlconstants";
import { formatFromISODate } from "@/utility/common-utils";
import { accessTypeName } from "@/utility/common-utils";

const DealershipDetail = () => {

    const { id } = useParams();
    const [viewUserPlanStats, setViewUserPlanStats] = useState([]);
    const [viewUserPlanDetail, setViewUserPlanDetail] = useState([]);

    const requestData = async () => {
        let viewUserPlanStats = await request({
            url: `api/v1/private/userPlan/viewUserPlanStats?userPlanId=${id}`,
            method: "GET"
        });

        let viewUserPlanDetail = await request({
            url: `api/v1/private/userPlan/viewUserPlanDetail?userPlanId=${id}`,
            method: "GET"
        });
        setViewUserPlanStats(viewUserPlanStats);
        setViewUserPlanDetail(viewUserPlanDetail);
    }
    useEffect(() => {
        requestData()
    }, []);

    return (
        <div>
            <MainAdsLayout sideBanner>
                <PageTitle headingPart1={viewUserPlanDetail && viewUserPlanDetail.response && viewUserPlanDetail.response.userPlans && viewUserPlanDetail.response.userPlans[0].categoryName} headingPart2={"Dealerships"}></PageTitle>
                <div className="pe-lg-3">

                    <div className="card rounded-4">
                        <div className="card-body kg-bg-mustard shadow-sm rounded-4">
                            <div className="row px-3">
                                <div className="col-lg-9 col-md-8 col-6">
                                    <div className="fs-5 fw-bold">{viewUserPlanDetail && viewUserPlanDetail.response && viewUserPlanDetail.response.userPlans && viewUserPlanDetail.response.userPlans[0].planGroup} Plan </div>
                                    <div className="mt-4">
                                        Valid thru :  <b>{viewUserPlanDetail && viewUserPlanDetail.response && viewUserPlanDetail.response.thisPlan && formatFromISODate(viewUserPlanDetail.response.thisPlan.endDate, 'dd-MM-yyyy')}</b>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-6 text-end border-start border-2 border-secondary">
                                    <Link to="">
                                        <div className="text-dark">
                                            Upgrade plan <i className="bi bi-chevron-right fw-bold" />
                                        </div>
                                    </Link>
                                    <Link to="">
                                        <div className="text-dark mt-4">
                                            Renew plan <i className="bi bi-chevron-right fw-bold" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="card-body">
                                <div className="d-flex px-3 align-items-center">
                                    <div className="flex-fill">
                                        <Link to={MY_LEADS}>
                                            My Leads ({viewUserPlanStats && viewUserPlanStats.response && viewUserPlanStats.response.leads && viewUserPlanStats.response.leads?.length})
                                        </Link>
                                    </div>
                                    <div>
                                        <i className="bi bi-chevron-right" />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex px-3 align-items-center">
                                    <div className="flex-fill">
                                        <Link to={`${DEALERPOSTINGS}?type=${accessTypeName(viewUserPlanDetail.response?.userPlans && viewUserPlanDetail.response.userPlans[0].accessType)}&status=SOLD_OUT`}>
                                            Sold items (0)
                                        </Link>
                                    </div>
                                    <div>
                                        <i className="bi bi-chevron-right" />
                                    </div>
                                </div>
                                <hr />

                                <div className="d-flex px-3 align-items-center">
                                    <div className="flex-fill">
                                        <Link to={`${DEALERPOSTINGS}?type=${accessTypeName(viewUserPlanDetail.response?.userPlans && viewUserPlanDetail.response.userPlans[0].accessType)}&status=ACTIVE`}>
                                            Active postings (0)
                                        </Link>
                                    </div>
                                    <div>
                                        <i className="bi bi-chevron-right" />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex px-3 align-items-center">
                                    <div className="flex-fill">
                                        <Link to={`${DEALERPOSTINGS}?type=${accessTypeName(viewUserPlanDetail.response?.userPlans && viewUserPlanDetail.response.userPlans[0].accessType)}&status=INACTIVE`}>
                                            Inactive posts (0)
                                        </Link>
                                    </div>
                                    <div>
                                        <i className="bi bi-chevron-right" />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex px-3 align-items-center">
                                    <div className="flex-fill">
                                        <Link to={`${DEALERPOSTINGS}?type=${accessTypeName(viewUserPlanDetail.response?.userPlans && viewUserPlanDetail.response.userPlans[0].accessType)}&status=PENDING_FOR_APPROVAL`}>
                                            Pending for approval (0)
                                        </Link>
                                    </div>
                                    <div>
                                        <i className="bi bi-chevron-right" />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex px-3 align-items-center">
                                    <div className="flex-fill">
                                        <Link to={`${DEALERPOSTINGS}?type=${accessTypeName(viewUserPlanDetail.response?.userPlans && viewUserPlanDetail.response.userPlans[0].accessType)}&status=APPROVED`}>
                                            Approved Posting (0)
                                        </Link>
                                    </div>
                                    <div>
                                        <i className="bi bi-chevron-right" />
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex px-3 align-items-center">
                                    <div className="flex-fill">
                                        <Link to={`${DEALERPOSTINGS}?type=${accessTypeName(viewUserPlanDetail.response?.userPlans && viewUserPlanDetail.response.userPlans[0].accessType)}&status=REJECT_AND_REFUND`}>
                                            Rejected Posts (0)
                                        </Link>
                                    </div>
                                    <div>
                                        <i className="bi bi-chevron-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <div className="d-flex px-4 py-3 align-items-center rounded-4 kg-bg-light">
                            <div className="flex-fill ps-2">
                                <Link to={PROFILE_DETAILS}>
                                    Edit Dealer Profile
                                </Link>
                            </div>
                            <div className="pe-2">
                                <i className="bi bi-chevron-right" />
                            </div>
                        </div>
                    </div>
                </div>
            </MainAdsLayout>
            <OfferZone />
        </div>
    );
};

export default DealershipDetail;