import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { changePassword_finalize } from '@/endpoints/amplify/auth'
import { Success, Error } from '@/_service/notification/notification';
import { useLanguageContext } from '@/hooks/context-hooks';
import MainAdsLayout from '@/_component/MainAdsLayout';
import PageTitle from '@/_component/Common/page/PageTitle';
import Button from '@/_component/Common/button/Button';

const ResetPassword = () => {
  const { language } = useLanguageContext();
  const { state } = useLocation();
  const [currentRouteData, setCurrentRouteData] = useState(state ? JSON.parse(state) : null);
  if (!currentRouteData?.route) { window.location.href = "/"; }

  const schema = {
    "register_phone": currentRouteData?.phoneNumber,
    "otp": "",
    "newPassword": "",
    "newPassword_confirm": ""
  }
  const [selectedItem, setSelectedItem] = useState(schema);

  const handleInputChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSelectedItem((prevState) => ({ ...prevState, [name]: value, }));
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (selectedItem.otp === "") {
      Error("Otp can't be empty");
    } else if (selectedItem.newPassword === "") {
      Error("Password can't be empty");
    } else if (selectedItem.newPassword_confirm === "") {
      Error("Confirm password can't be empty");
    } else if (selectedItem.newPassword !== selectedItem.newPassword_confirm) {
      Error("Please ensure passwords are the same.");
    } else {
      await changePassword_finalize(selectedItem?.register_phone, selectedItem?.otp, selectedItem?.newPassword, handleChangeUserRegisterUser);
    }
  };

  const handleChangeUserRegisterUser = (type, err) => {
    if (type) {
      Success("Password updated successfully")
      setTimeout(() => {
        window.location.href = "/"
      }, 500);
    } else if(err) {
      Error(err?.message);
    }
  };

  return (
    <MainAdsLayout sideBanner>
      <PageTitle headingPart1={language("label.reset_password")}></PageTitle>
      <div className='offset-md-2 col-md-7 offset-lg-0 px-lg-3 p-xl-0 col-lg-12 offset-xl-2 col-xl-7'>
        <form className="row" onSubmit={handleChangePassword}>
          <div className="mb-3">
            <label htmlFor="oldPassword" className="form-label"> {language("label.enter_otp")}<span className="text-danger">*</span></label>
            <input type="text" className="form-control" id="otp" name='otp' placeholder={language("label.enter_otp")} onChange={handleInputChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label">{language("Enter_new_password")}  <span className="text-danger">*</span></label>
            <input type="password" className="form-control" id="newPassword" name='newPassword' placeholder={language("Enter_new_password")} onChange={handleInputChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">{language("label.re_enter_password")}  <span className="text-danger">*</span></label>
            <input type="password" className="form-control" id="confirmPassword" name='newPassword_confirm' placeholder={language("label.re_enter_password")} onChange={handleInputChange} />
          </div>
          <div className='text-center'>
            <Button primary solid rounded type="submit" className="px-5">{language("label.confirm")}</Button>
          </div>
        </form>
      </div>
    </MainAdsLayout>
  );
};

export default ResetPassword;
