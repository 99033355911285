import React, { useState, useRef } from 'react';
// import './verifyOTP.css';
import { useLocation } from "react-router-dom";
import { resendConfirmationCode, confirmSignUp, signIn } from '@/endpoints/amplify/auth'
import { useLanguageContext } from '@/hooks/context-hooks';
import Button from '@/_component/Common/button/Button';
import MainAdsLayout from '@/_component/MainAdsLayout';
import PageTitle from '@/_component/Common/page/PageTitle';
import { useLoginToggle } from '@/hooks/login-toggle';

const VerifyOTP = () => {
  const { language } = useLanguageContext();
  const handleLoginToggle = useLoginToggle();

  const { state } = useLocation();
  const [otpInputs, setOtpInputs] = useState(['', '', '', '', '', '']);
  const otpInputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));

  const [currentRoutelanguageList, setCurrentRoutelanguageList] = useState(state ? JSON.parse(state) : null);
  // if (currentRoutelanguageList?.route == null) {
  //   Error("this page can't be accessed");
  //   window.location.href = "/";
  // }
  const handleOtpInputChange = (index, value) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);

    if (/^\d$/.test(value) && index < otpInputs.length - 1) {
      otpInputRefs.current[index + 1].current.focus();
    }
  }


  const handleOtpInputKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otpInputs[index] && index > 0) {
      e.preventDefault();
      otpInputRefs?.current[index - 1].current.focus();
    }
  }

  const handleVerifyOTP = (e) => {
    const otp = otpInputs.join('');
    confirmSignUp(currentRoutelanguageList?.phoneNumber, otp, handleOtpStatus, e)
  }

  const handleOtpStatus = (type, username, e) => {
    if (type) {
      signIn(currentRoutelanguageList.phoneNumber, currentRoutelanguageList.password, e);
    }
  }

  const handleResendOTP = () => {
    setOtpInputs(['', '', '', '', '', '']);
    resendConfirmationCode(currentRoutelanguageList?.phoneNumber, resendOTPForAuth)
  }

  const resendOTPForAuth = (type, user) => {
    if (!type) {
      Error('due to server issue please try again later...')
    }
  }

  return (
    <MainAdsLayout sideBanner>
      <PageTitle headingPart1={language("verify_otp")}></PageTitle>
      <div>
      <p className="text-center">{language("We_have_sent_a_verification_code_to")}</p>
          <p className="text-center mb-4">{currentRoutelanguageList?.phoneNumber}</p>
          <div className="col-lg-6 col-md-7 mx-auto text-center">
            <div className="d-flex flex-row justify-content-center mb-4">
              {otpInputs?.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  className="m-2 text-center form-control rounded"
                  maxLength="1"
                  value={value}
                  onChange={(e) => handleOtpInputChange(index, e.target.value, e)}
                  onKeyDown={(e) => handleOtpInputKeyDown(e, index)}
                  ref={otpInputRefs.current[index]} />
              ))}
            </div>
            <div className="text-center">
              <Button primary solid rounded className="px-5 fs-5" onClick={(e) => handleVerifyOTP(e)}>
                {language("verify_otp")}
              </Button>
            </div>
            <div className="mt-2">
              <span className="me-2">
                {language("label.did_nt_receive_code")}
              </span>
              <a onClick={(e) => handleResendOTP(e)}>{language("label.resend_now")}</a>
            </div>
            <div id="resentotpmsg" style={{ "display": "none" }} className="alert_m"></div>
            <hr />
            <p className="kg-dark-gray mb-3">
              {language("If_you_are_member_please")}
            </p>

            <div className="text-center">
              <Button primary outline rounded className="px-5 fs-5" onClick={handleLoginToggle}>{language("label.login")}</Button>
            </div>
          </div>
      </div>
    </MainAdsLayout>
  );
};

export default VerifyOTP;