import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getUserData } from '@/store/reducer/userSlice/userSlice';
import { Error } from '@/_service/notification/notification';
import { signUp, resendConfirmationCode } from '@/endpoints/amplify/auth'
import { useNavigate } from 'react-router';
import { useLanguageContext } from '@/hooks/context-hooks';
import MainAdsLayout from '@/_component/MainAdsLayout';
import PageTitle from '@/_component/Common/page/PageTitle';
import Button from '@/_component/Common/button/Button';
import { useLoginToggle } from '@/hooks/login-toggle';
import { S3_HOST } from '@/_constant/urlconstants';

export default function Register() {
  const userDetails = useSelector(getUserData)
  const { language } = useLanguageContext();
  const handleLoginToggle = useLoginToggle();
  let navigate = useNavigate()

  if (Object.keys(userDetails).length > 0) { navigate("/") }

  const url = `${S3_HOST}/country/countrycodes.json`;
  const [countryCode, setCountryCode] = useState('91');
  const [countryName, setCountryName] = useState("IN");
  const [countryList, setCountryList] = useState([]);

  const schema = {
    "register_name": "",
    "countryCode": countryCode,
    "register_phone": "",
    "newPassword": "",
    "newPassword_confirm": "",
    "referralcodes": ""
  }
  const [selectedItem, setSelectedItem] = useState(schema);

  useEffect(() => {
    const fetchlanguageList = async () => {
      try {
        const response = await fetch(url);
        const languageList = await response.json();
        setCountryList(languageList);
      } catch (error) {
        console.error('Error - ', error);
      }
    };
    fetchlanguageList();
  }, []);

  // Handles the form input
  const inputsFromHandler = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSelectedItem((prevState) => ({ ...prevState, [name]: value, }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (selectedItem?.register_name === "") {
      Error("Name can't be empty");
    } else if (selectedItem?.register_phone === "" || selectedItem?.register_phone.length < 5) {
      Error("Phone number can't be empty");
    } else if (selectedItem?.newPassword === "") {
      Error("Password can't be empty");
    } else if (selectedItem?.newPassword_confirm === "") {
      Error("Re password can't be empty");
    } else if (selectedItem?.newPassword !== selectedItem?.newPassword_confirm) {
      Error('Please ensure passwords are the same.');
    } else if (selectedItem?.register_phone.length <= 9 || selectedItem?.register_phone.length > 10) {
      Error('Please enter valid mobile number');
    } else {
      const userDetails = await signUp("+91" + selectedItem?.register_phone, selectedItem?.newPassword, null, selectedItem?.register_name, 3, handleChangeUserRegisterUser);
      if (userDetails) {
        const item = {
          route: '/register',
          phoneNumber: "+91" + selectedItem?.register_phone,
          inputs: selectedItem,
          success: userDetails,
          refCode: userDetails?.referralcodes,
          password: selectedItem?.newPassword,
        }
        navigate("/verify_register", { replace: true, state: JSON.stringify(item) });
      }
    }
  }



  const handleChangeUserRegisterUser = (username, type, users) => {
    resendConfirmationCode(username, resendOTPForAuth)
  };

  const resendOTPForAuth = (type) => {
    if (type) {
      const item = {
        route: '/register',
        phoneNumber: "+91" + selectedItem?.register_phone,
        inputs: selectedItem,
        success: userDetails,
        refCode: userDetails?.referralcodes
      }
      navigate("/verify_register", { replace: true, state: JSON.stringify(item) });
    } else {
      Error("invalid registration please try again....")
    }
  }

  return (
    <MainAdsLayout sideBanner>
      <PageTitle headingPart1={language("label.register")}></PageTitle>
      <div className='pe-4'>
        <form onSubmit={handleRegister} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">{language("label.enter_your_name")}<span className="text-danger">*</span></label>
            <input onChange={inputsFromHandler} type="text" className="form-control" id="name" name='register_name' placeholder={language("label.enter_your_name")} />
          </div>
          <div className="col-md-6">
            <label htmlFor="mobile" className="form-label">{language("label.enter_phone_number")}<span className="text-danger">*</span></label>
            <div className="input-group mb-3">
              <select disabled readOnly onChange={inputsFromHandler} className='form-select rounded-start-4' value={countryCode} style={{ maxWidth: "5rem" }} name="countryCode">
                {countryList?.map((option, index) => (
                  <option key={index} value={option.value}>{option?.display?.replace(/&nbsp;/g, ' ')}</option>
                ))}
              </select>
              <input onChange={inputsFromHandler} type="text" className="form-control mb-0" id="mobile" name='register_phone' placeholder={language("label.enter_phone_number")} />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="password" className="form-label">{language("label.enter_password")}<span className="text-danger">*</span></label>
            <input onChange={inputsFromHandler} type="password" className="form-control" id="password" name='newPassword' placeholder={language("label.enter_password")} />
          </div>
          <div className="col-md-6">
            <label htmlFor="confirm-password" className="form-label">{language("label.re_enter_password")}<span className="text-danger">*</span></label>
            <input onChange={inputsFromHandler} type="password" className="form-control" id="confirm-password" name='newPassword_confirm' placeholder={language("label.re_enter_password")} />
          </div>
          <div className="col-md-6">
            <label htmlFor="referral-code" className="form-label">{language("label.referral_code")}</label>
            <input onChange={inputsFromHandler} type="text" className="form-control" id="referral-code" name='referralcodes' placeholder={language("label.referral_code")} />
          </div>
          <div className="col-12 text-center">
            <Button primary solid rounded type="submit" className="w-50">Send OTP</Button>
          </div>
        </form>

        <div className="row py-3">
          <hr />
          <p className='text-center'>{language("If_you_are_member_please")}</p>
          <div className="col-12 text-center">
            <Button primary solid rounded className="mx-auto" onClick={handleLoginToggle}>{language("label.login")}</Button>
          </div>
        </div>
      </div>
    </MainAdsLayout>
  )
}
