import { toggleLogin } from "@/store/reducer/loginSlice/loginSlice";
import { useDispatch } from "react-redux"

export const useLoginToggle = () =>{
    const dispatch = useDispatch();

    const handleLoginToggle = () =>{
        dispatch(toggleLogin())
    }
    return handleLoginToggle;
}