import React from 'react';
import "../banner.css";
import { ADSERVER_ID } from '@/_constant/appConstant';

const BottomBanner = () => {

  return (
    <div className="bg-light py-3">
      <div className='container'>
        <div className='d-flex align-items-center flex-column flex-lg-row gap-1'>
          <div className='flex-fill banner-box'>
            <ins data-revive-zoneid="1" data-revive-id={ADSERVER_ID}></ins>
          </div>
          <div className='flex-fill banner-box'>
            <ins data-revive-zoneid="2" data-revive-id={ADSERVER_ID}></ins>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomBanner;
