import "./Login.css";
import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { signIn, changePassword_initiate } from '@/endpoints/amplify/auth';
import { Error } from '@/_service/notification/notification';
import { useNavigate } from "react-router-dom";
import { setLoginPopup } from "@/store/reducer/loginSlice/loginSlice";
import { useDispatch } from "react-redux";
import { S3_HOST } from '@/_constant/urlconstants';
import { useLanguageContext } from '@/hooks/context-hooks';
import Button from '@/_component/Common/button/Button';
import { useLoginToggle } from '@/hooks/login-toggle';
import * as Yup from 'yup';

export default function Login({ handleChangeRegistrationspage, show }) {
  const { language } = useLanguageContext();
  const handleLoginToggle = useLoginToggle();

  const url = `${S3_HOST}/country/countrycodes.json`;
  const [countryCode, setCountryCode] = useState(91);
  const [countryList, setCountryList] = useState([]);
  const [loginFormData, setLoginFormData] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  let navigate = useNavigate()
  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must be digits only")
      .min(10, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number must not exceed 10 digits")
      .required("Mobile number is required"),
    password: Yup.string().required("Password is required")
  });

  useEffect(() => {
    $("#login_error").hide();
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setCountryList(data);
      } catch (error) {
        console.error('Error - ', error);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData({ ...loginFormData, [name]: value });
  };

  const validateLoginForm = (e) => {
    try {
      return loginSchema.validateSync(loginFormData, { abortEarly: false });
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      setTimeout(() => setErrors({}), 4000);
    }
  }

  const signUserIn = (event) => {
    event.preventDefault();
    if (!validateLoginForm()) return;
    const username = `+${countryCode}${loginFormData.username}`;
    signIn(username, loginFormData.password, "", event);
  }

  const forgetPassword = (e) => {
    const username = $('#login_username').val();
    if (username != "") {
      changePassword_initiate(`${countryCode}${loginFormData.username}`, changePasswordCallback)
    } else {
      Error('Please enter a username');
    }
  }

  const changePasswordCallback = (type, user) => {
    if (type) {
      const item = {
        username: user,
        route: 'password',
        phoneNumber: `${countryCode}${loginFormData.username}`,
      }
      dispatch(setLoginPopup(false));
      navigate("/resetpassword", { replace: true, state: JSON.stringify(item) })
    }
  }

  return (
    <div className={`modal fade login-popup ${show}`} data-bs-backdrop="static" data-bs-keyboard="false" id="login_popup" style={{ "display": "block", backgroundColor: "rgba(0, 0, 0, 0.568)"  }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-5">
          <div className='text-end'>
            <Button noSpacing
              className="btn-close fs-6 me-3 mt-3"
              aria-label="Close" onClick={handleLoginToggle}></Button>
          </div>
          <div className="modal-body p-0">
            <div className="login-popup-main">
              <div className="login-inner px-5">
                <h3 className="text-center">LOGIN</h3>
                <form onSubmit={signUserIn}>
                  <div className="input-group">
                    <select value={countryCode} name="countryCode" id="countryCodeId_login" className="form-select rounded-start-4" disabled
                      style={{ maxWidth: "5rem" }}>
                      {countryList.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option?.display?.replace(/&nbsp;/g, ' ')}
                        </option>
                      ))}
                    </select>
                    <input id="login_username" minLength="10" maxLength="10" type="text" name="username" className="form-control rounded-end-4" value={loginFormData.username} onChange={handleInputChange} placeholder={language("label.enter_phone_number")} />
                  </div>
                  {errors.username && <div><small className='text-danger'>{errors.username}</small></div>}
                  <div className="mt-3">
                    <input
                      id="login_password"
                      type="password"
                      name="password"
                      className="form-control rounded-4"
                      placeholder={language("label.enter_you_login_password")}
                      value={loginFormData.password} onChange={handleInputChange}
                    />
                  </div>
                  {errors.password && <div><small className='text-danger'>{errors.password}</small></div>}
                  <button
                    id="loginbuttonid"
                    type="submit"
                    className="mt-5"
                  >
                    {language("label.login")}
                  </button>
                </form>
                <div id="login_error" lang="en" className="alert_m"></div>
                <div
                  className="text-right forgot_pass"
                  data-bs-dismiss="modal"
                >
                  <span className="link" onClick={(e) => forgetPassword(e)}>{language("label.forgot_password")}?</span>
                </div>
              </div>
              <div className="login-bottom">
                <p lang="en">
                  {language("If_you_are_not_a_member")}
                </p>
                <div className="text-center">
                  <button
                    data-bs-dismiss="modal"
                    className="register_modal_btn"
                    onClick={(e) => { handleChangeRegistrationspage(e); }}
                  >
                    {language("label.register")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

