import ReactDOM from "react-dom";
import Button from "../button/Button";


export function DialogBoxActions({children}){
    return (
        <div className="d-flex justify-content-end gap-3 mt-4">
            {children}
        </div>
    )
}

export default function DialogBox({ title, children, onClose }) {
    
    const dialogBoxHtml = (
        <div className="position-fixed top-0 start-0 overflow-hidden w-100" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 0.568)", zIndex: "99" }}>
            <div className="position-fixed top-50 start-50 translate-middle" style={{ minWidth: "30rem", maxWidth: "40rem" }}>
                <div className="position-relative rounded-4 shadow kg-bg-green">
                    <div className="kg-bg-green py-1 px-3 kg-white d-flex justify-content-between align-items-center rounded-4">
                        <h5>{title}</h5>
                        <Button type="button" className="kg-white" onClick={onClose}><i className="fs-5 bi bi-x-lg"></i></Button>
                    </div>
                    <div className="bg-white py-3 px-3 content rounded-4 border overflow-y-auto" style={{minHeight: "6rem", maxHeight: "100%"}}>{children}</div>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(dialogBoxHtml, document.querySelector(".dialog-box-container"));
}