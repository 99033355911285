import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import OfferZone from "@/_component/Common/theme/offer-zone/OfferZone";
import { getCurrentSession } from "@/endpoints/amplify/auth";
import { API_PUBLIC_HOST } from "@/_constant/urlconstants";
import { Success, Error } from "@/_service/notification/notification";
import { saveAddress, saveListItem } from "@/_service/share/share";
import { useLanguageContext } from "@/hooks/context-hooks";
import MainAdsLayout from "@/_component/MainAdsLayout";
import PageTitle from "@/_component/Common/page/PageTitle";
import Button from "@/_component/Common/button/Button";
import DialogBox from "@/_component/Common/Dialog/DialogBox";
import PageFilter from "@/_component/Common/page/PageFilter";

const MyAddress = () => {
    const { language } = useLanguageContext();
    const { state } = useLocation();
    let navigate = useNavigate();
    const { data, route, categoryId, editType } = state ? JSON.parse(state) : {};


    const [currentRouteData, setCurrentRouteData] = useState(data);

    const [addressId, setAddressId] = useState(data?.address);
    const [addressList, setAddress] = useState(null);
    /*********Address file*****************/
    const [isAddressPopup, setIsAddressPopup] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [countryList, setCountry] = useState(null);
    const [tehsilList, setTehsil] = useState(null);
    const [stateList, setState] = useState(null);
    const [districtList, setDistrict] = useState(null);

    const schema = {
        name: "",
        phoneNumber: "",
        villageCity: "",
        houseNoStreet: "",
        tehsil: "",
        district: "",
        pincode: "",
        state: "",
        country: "",
        isPrimary: false,
    };
    const [addNewAddress, setNewAddress] = useState(schema);

    // Handles the form input
    const selectAddressHandler = (value) => {
        setAddressId(value);
    };

    useEffect(() => {
        if (editType === "general") {
            getCurrentSession(getAddressList);
        } else {
            getCurrentSession(getCategoryAddressList);
        }
    }, []);

    const getAddressList = (success, user, jwtToken) => {
        const URL = `${API_PUBLIC_HOST}/user/listAddress`;
        axios({
            method: "get",
            url: URL,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: jwtToken,
            },
        }).then((response) => {

            if (data?.address === "") {
                for (let index = 0; index < response?.data?.response.length; index++) {
                    if (response?.data?.response[index]?.isPrimary) {
                        setAddressId(response?.data?.response[index]?.id);
                        break;
                    }
                }
            }
        });
    };

    const getCategoryAddressList = (success, user, jwtToken) => {
        const URL = `${API_PUBLIC_HOST}/user/listAddress`;

        axios({
            method: "get",
            url: URL,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: jwtToken,
            },
        }).then((response) => {
            var addressList = response?.data?.response;
            addressList.sort((a, b) => (a.isPrimary == true && b.isPrimary == false) ? -1 : 1)
            setAddress(addressList);


            if (data?.address === "") {
                for (let index = 0; index < response?.data?.response.length; index++) {
                    if (response?.data?.response[index]?.isPrimary) {
                        setAddressId(response?.data?.response[index]?.id);
                        break;
                    }
                }
            }
        });
    };

    // Handles the form input
    const handleChangeAddAddressInfo = (e, type = "") => {
        e.preventDefault();
        const { name, value } = e.target;
        setNewAddress((prevState) => ({ ...prevState, [name]: value }));
        if (type !== "") {
            handleChangeLocationWiseDetails(type, parseInt(value));
        }
    };

    const handleChangeOpenAddressPopup = () => {
        setNewAddress(schema);
        setIsAddressPopup(true);
        handleChangeLocationWiseDetails("COUNTRY");
    };

    const handleChangeLocationWiseDetails = (type, query) => {
        getCurrentSession((success, user, jwtToken) => {
            const url =
                `${API_PUBLIC_HOST}/lookup/location?type=` + type + `&query=` + query;
            axios({
                method: "GET",
                url: url,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                },
            })
                .then((response) => {
                    if (type === "COUNTRY") {
                        setCountry(response?.data?.response);
                    } else if (type === "STATE") {
                        setState(response?.data?.response);
                    } else if (type === "DISTRICT") {
                        setDistrict(response?.data?.response);
                    } else if (type === "TEHSIL") {
                        setTehsil(response?.data?.response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    const handleChangeClosePopup = () => {
        setIsAddressPopup(false);
        setNewAddress(null);
        setButtonDisabled(false);
    };

    const handleChangeAddNewAddress = (e) => {
        e.preventDefault();
        if (addNewAddress?.name === "") {
            Error("name can't be empty");
        } else if (addNewAddress.houseNoStreet === "") {
            Error("houseNoStreet can't be empty");
        } else if (addNewAddress.villageCity === "") {
            Error("villageCity can't be empty");
        } else if (addNewAddress.country === "" || addNewAddress.country !== "98") {
            Error("country can't be empty Or please do select india");
        } else if (addNewAddress.state === "") {
            Error("state can't be empty");
        } else if (addNewAddress.district === "") {
            Error("district can't be empty");
        } else if (addNewAddress.tehsil === "") {
            Error("tehsil can't be empty");
        } else if (addNewAddress.pincode === "") {
            Error("pincode can't be empty");
        } else {
            saveAddressData(addNewAddress);
        }
    };

    const saveAddressData = async (data) => {
        const status = await saveAddress(data);
        if (status?.data?.success) {
            handleChangeClosePopup();
            Success("Address saved successfully");
            getCurrentSession(getAddressList);
        } else if (status?.error) {
            Error("Something went wrong Please try again later");
            console.log(status?.error);
        }
    };

    const handleChangeDeleteAddressItem = (id) => {
        getCurrentSession((success, user, jwtToken) => {
            const url = `${API_PUBLIC_HOST}/user/deleteAddress?`;
            let URL = url + "addressId=" + id;
            axios({
                method: "DELETE",
                url: URL,
                headers: {
                    Accept: "application/json",
                    Authorization: jwtToken,
                },
            }).then((response) => {
                handleChangeClosePopup();
                Success("Delete successfully");
                getCurrentSession(getAddressList);
            });
        });
    };

    const handleChangeEditAddressItem = (items) => {
        setIsAddressPopup(true);
        setNewAddress(items);
        handleChangeLocationWiseDetails("COUNTRY");
        handleChangeLocationWiseDetails("STATE", items.country);
        handleChangeLocationWiseDetails("DISTRICT", items.state);
        handleChangeLocationWiseDetails("TEHSIL", items.district);
        setButtonDisabled(true);
    };

    /************************ save Listing data */

    // Handles the Save Listing after change
    const saveItemData = () => {
        if (addressId === undefined || addressId === "") {
            Error("please select address");
        } else {
            saveListData(data);
        }
    };

    const saveListData = async (itemDetails) => {
        itemDetails.address = addressId;
        const response = await saveListItem(itemDetails);
        if (response.status === 200 && response?.data?.success) {
            setCurrentRouteData(response?.data?.response);
            itemDetails["id"] = response.data?.response?.id;
            const item = {
                data: currentRouteData,
                route: route,
                categoryId: response?.data?.response?.categoryId,
                userPlanId: response?.data?.response?.userPlanId,
                ListId: response?.data?.response?.id,
                addressId: addressId,
                editType: editType,
            };
            Success("successfully saved");
            navigate("/uploadphotos", { state: JSON.stringify(item) });
        } else {
            Error("something went wrong");
        }
    };

    const handleChangeBackButton = (routeData, response) => {
        const item = {
            item: currentRouteData,
            route: route,
            ListId: response?.data?.response?.id,
        };
        const data = JSON.stringify(item);
        Success("Delete successfully");
        navigate("/" + route, { state: { data } });
    };

    return (
        <>
            <MainAdsLayout sideBanner>
                <PageTitle headingPart1={language("label.my_address")} headingPart2={language("label.information")}>
                    <PageFilter>
                        <div className="pe-4">
                            <Button primary noSpacing onClick={handleChangeOpenAddressPopup}><i className="bi bi-plus-lg fw-bold"></i> Add new address</Button>
                        </div>
                    </PageFilter>
                </PageTitle>
                <div className="pe-lg-3 pe-0">
                    {editType != "general" && (
                        <div className="row row-cols-1 gx-3 gy-3 row-cols-md-2">
                            {addressList
                                ?.filter((item) => !item.isPrimary || !!item?.isPrimary)
                                .map((item, idx) => (
                                    <div className="col" key={idx}>
                                        <div className="card shadow-sm border p-3">
                                            <h4>{item?.name}</h4>
                                            <div className="mb-2">
                                                <p className="m-0">
                                                    {`${item?.houseNoStreet}, ${item?.villageCity}, ${language(item?.display_code_tehsil)}, ${language(item?.display_code_district)}, `}
                                                </p>
                                                <p className="m-0">{`${language(item?.display_code_state)} - ${item?.pincode}`}</p>
                                                <span>{language(item?.country.IN)}</span>
                                            </div>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-between">
                                                <Button small primary={item?.isPrimary} secondary={!item?.isPrimary} solid={item?.isPrimary} outline={!item?.isPrimary} rounded onClick={() => selectAddressHandler(item?.id)}>
                                                    {language("label.select")}
                                                </Button>
                                                <div className="d-flex gap-4">
                                                    <Button primary noSpacing type="button" onClick={() => handleChangeEditAddressItem(item)}>
                                                        <i className="bi bi-pencil-fill"></i>
                                                    </Button>
                                                    {item.isPrimary ?
                                                        <span className="kg-green">Primary</span> :
                                                        <Button danger noSpacing onClick={() => handleChangeDeleteAddressItem(item?.id)}><i className="bi bi-trash"></i></Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </MainAdsLayout>

            <OfferZone />

            {/* <!-- address popup  --> */}
            {isAddressPopup && (
                <DialogBox title={"Add or Update Address"} onClose={handleChangeClosePopup}>
                    <form className="row gy-2 px-2" onSubmit={handleChangeAddNewAddress}>
                        <div className="col-md-6">
                            <label htmlFor="name" className="kg-secondary form-label">
                                <small>Name <span className="text-danger">*</span></small>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Name"
                                value={addNewAddress["name"]}
                                onChange={(e) => handleChangeAddAddressInfo(e)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="houseNoStreet" className="kg-secondary form-label">
                                <small>House No Street
                                    <span className="text-danger">*</span>
                                </small>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="houseNoStreet"
                                name="houseNoStreet"
                                placeholder="houseNoStreet"
                                value={addNewAddress["houseNoStreet"]}
                                onChange={(e) => handleChangeAddAddressInfo(e)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="villageCity" className="kg-secondary form-label">
                                <small>Village City <span className="text-danger">*</span></small>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="villageCity"
                                name="villageCity"
                                placeholder="Address"
                                value={addNewAddress["villageCity"]}
                                onChange={(e) => handleChangeAddAddressInfo(e)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="country" className="kg-secondary form-label">
                                <small>Country <span className="text-danger">*</span></small>
                            </label>
                            <select
                                className="form-select"
                                id="country"
                                name="country"
                                placeholder="country"
                                value={addNewAddress["country"]}
                                onChange={(e) =>
                                    handleChangeAddAddressInfo(e, "STATE")
                                }
                            >
                                <option value="" disabled>Select Country</option>
                                {countryList?.map((country, i) => (
                                    <option key={i} value={country.id}>
                                        {country?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="state" className="kg-secondary form-label">
                                <small>State <span className="text-danger">*</span></small>
                            </label>
                            <select
                                type="text"
                                className="form-select"
                                id="state"
                                name="state"
                                placeholder="state"
                                value={addNewAddress["state"]}
                                onChange={(e) =>
                                    handleChangeAddAddressInfo(e, "DISTRICT")
                                }
                            >
                                <option value="" disabled>Select State</option>
                                {stateList?.map((state, i) => (
                                    <option key={i} value={state.id}>
                                        {state?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="district" className="kg-secondary form-label">
                                <small>District <span className="text-danger">*</span></small>
                            </label>
                            <select
                                type="text"
                                className="form-select"
                                id="district"
                                name="district"
                                placeholder="District"
                                value={addNewAddress["district"]}
                                onChange={(e) =>
                                    handleChangeAddAddressInfo(e, "TEHSIL")
                                }
                            >
                                <option value="" disabled>Select District</option>
                                {districtList?.map((district, i) => (
                                    <option key={i} value={district.id}>
                                        {district?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="tehsil" className="kg-secondary form-label">
                                <small>Tehsil <span className="text-danger">*</span></small>
                            </label>
                            <select
                                type="text"
                                className="form-select"
                                id="tehsil"
                                name="tehsil"
                                placeholder="Tehsil"
                                value={addNewAddress["tehsil"]}
                                onChange={(e) => handleChangeAddAddressInfo(e)}
                            >
                                <option value="" disabled>Select Tehsil</option>
                                {tehsilList?.map((tehsil, i) => (
                                    <option key={i} value={tehsil.id}>
                                        {tehsil?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="pincode" className="kg-secondary form-label">
                                <small>Pincode <span className="text-danger">*</span></small>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="pincode"
                                name="pincode"
                                placeholder="Pincode"
                                value={addNewAddress["pincode"]}
                                onChange={(e) => handleChangeAddAddressInfo(e)}
                            />
                        </div>
                        <div className="col-12 text-end mt-3">
                            <Button type="submit" primary solid rounded>
                                {buttonDisabled === true ? "Update Address" : "Add Address"} <i className="bi bi-chevron-right ms-2"></i>
                            </Button>
                        </div>
                    </form>
                </DialogBox>
            )}
        </>
    );
};

export default MyAddress;